// Api
import InvoiceApi from '@/api/InvoiceApi';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Sub-modules
import Detail from './Detail';

export const fullNameSpace = 'Invoice/';

// TODO: This function should be moved into a util file
/** @returns { String } */
function getMessageFromError( error ) {
    return error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : error.message;
}

function insertIf( condition, object ) {
    return condition ? object : {};
}

export default {
    namespaced: true,
    modules: {
        Detail,
    },
    state: {
        /** @type { invoice[] } */
        list: [],

        /** @type { String[] } */
        invoiceStatusList: [],

        /** @type { Boolean } - used to tell status of this.list */
        inFlight: false,

        /** @type { String } */
        tableFilter: '',

        /** @type { Object } */
        filters: {
            /** @type { String } */
            isbn: '',
            /** @type { String } */
            supplierDocumentNumber: '',
            /** @type { String } */
            purchaseOrderNumber: '',
            /** @type { String } - 'YYYY-MM-DD' */
            dateStart: '',
            /** @type { String } - 'YYYY-MM-DD' */
            dateEnd: '',
            /** @type { import('@/typedefs/primarySupplier).primarySupplier } */
            primarySupplier: {},
            /** @type { import('@/typedefs/location').location } */
            location: {},
            /** @type { Object } */
            statusList: {},
        },
    },
    getters: {
        /** @returns { Number } */
        invoiceCount: ( state ) => state.list.length,

        /** @returns { Boolean } */
        hasInvoices: ( state, getters ) => (
            getters.invoiceCount > 0
        ),
        /** @returns { Boolean } */
        hasInvoiceStatusList: ( state ) => (
            state.invoiceStatusList.length > 0
        ),
        /** @returns { Boolean } */
        hasFilters: ( state ) => (
            !!state.filters.isbn
            || !!state.filters.supplierDocumentNumber
            || !!state.filters.purchaseOrderNumber
            || !!state.filters.dateStart
            || !!state.filters.dateEnd
            || ( Object.keys( state.filters.primarySupplier ).length > 0 )
            || ( Object.keys( state.filters.location ).length > 0 && !!state.filters.location.id )
            || Object.values( state.filters.statusList ).includes( true )
        ),
    },
    mutations: {
        SET_LIST( state, { list } ) {
            state.list = list.map( ( item ) => ( {
                ...item,
                formattedDate: FormatUtil.epochToUTCString( item.invoiceDate ),
            } ) );
        },
        SET_IN_FLIGHT( state, { boolean } ) {
            state.inFlight = boolean;
        },
        SET_INVOICE_STATUS_LIST( state, { invoiceStatusList } ) {
            state.invoiceStatusList = invoiceStatusList;
        },
        SET_FILTER_ISBN( state, { isbn } ) {
            state.filters.isbn = isbn;
        },
        SET_FILTER_PURCHASE_ORDER_NUMBER( state, { purchaseOrderNumber } ) {
            state.filters.purchaseOrderNumber = purchaseOrderNumber;
        },
        SET_FILTER_SUPPLIER_DOCUMENT_NUMBER( state, { supplierDocumentNumber } ) {
            state.filters.supplierDocumentNumber = supplierDocumentNumber;
        },
        SET_FILTER_DATE_START( state, { dateStart } ) {
            state.filters.dateStart = dateStart;
        },
        SET_FILTER_DATE_END( state, { dateEnd } ) {
            state.filters.dateEnd = dateEnd;
        },
        SET_FILTER_PRIMARY_SUPPLIER(state, { primarySupplier } ) {
            state.filters.primarySupplier = primarySupplier;
        },
        SET_FILTER_STATUS_LIST( state, { statusList } ) {
            state.filters.statusList = statusList;
        },
        SET_FILTER_LOCATION( state, { location } ) {
            state.filters.location = location;
        },
        SET_TABLE_FILTER( state, { tableFilter } ) {
            state.tableFilter = tableFilter;
        },
    },
    actions: {
        setFilterISBN( { commit }, { isbn } ) {
            commit('SET_FILTER_ISBN', { isbn } );
        },
        setFilterSupplierDocumentNumber( { commit }, { supplierDocumentNumber } ) {
            commit('SET_FILTER_SUPPLIER_DOCUMENT_NUMBER', { supplierDocumentNumber } );
        },
        setFilterPurchaseOrderNumber( { commit }, { purchaseOrderNumber } ) {
            commit('SET_FILTER_PURCHASE_ORDER_NUMBER', { purchaseOrderNumber } );
        },
        setFilterDateStart( { commit }, { dateStart } ) {
            commit('SET_FILTER_DATE_START', { dateStart } );
        },
        setFilterDateEnd( { commit }, { dateEnd } ) {
            commit('SET_FILTER_DATE_END', { dateEnd } );
        },
        setFilterPrimarySupplier( { commit }, { primarySupplier } ) {
            commit('SET_FILTER_PRIMARY_SUPPLIER', { primarySupplier } );
        },
        setFilterStatusList( { commit }, { statusList } ) {
            commit('SET_FILTER_STATUS_LIST', { statusList } );
        },
        setFilterLocation( { commit }, { location } ) {
            commit('SET_FILTER_LOCATION', { location } );
        },
        setTableFilter( { commit }, { tableFilter } ) {
            commit('SET_TABLE_FILTER', { tableFilter } );
        },
        initializeFilterStatusList( context ) {
            const statusList = {};
            context.state.invoiceStatusList.forEach( ( status ) => {
                statusList[ status ] = false;
            } );
            context.commit('SET_FILTER_STATUS_LIST', { statusList } );
        },

        reset( { commit, dispatch } ) {
            commit('SET_LIST', { list: [] } );
            commit('SET_IN_FLIGHT', { boolean: false } );
            commit('SET_FILTER_ISBN', { isbn: '' } );
            commit('SET_FILTER_PURCHASE_ORDER_NUMBER', { purchaseOrderNumber: '' } );
            commit('SET_FILTER_SUPPLIER_DOCUMENT_NUMBER', { supplierDocumentNumber: '' } );
            commit('SET_FILTER_DATE_START', { dateStart: '' } );
            commit('SET_FILTER_DATE_END', { dateEnd: '' } );
            commit('SET_FILTER_PRIMARY_SUPPLIER', { primarySupplier: {} } );
            commit('SET_FILTER_LOCATION', { location: { id: 0 } } );
            commit('SET_TABLE_FILTER', { tableFilter: '' } );
            dispatch('initializeFilterStatusList');
        },

        /**
         * Used to set the list when sorting the Invoice list
         */
        async setList( context, { list } ) {
            context.commit('SET_LIST', { list } );
        },

        /**
         * Load in the invoice status list
         */
        async getInvoiceStatusList( context ) {
            if ( context.getters.hasInvoiceStatusList ) return;
            try {
                const { data } = await InvoiceApi.getInvoiceStatusList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                } );
                context.commit('SET_INVOICE_STATUS_LIST', {
                    invoiceStatusList: data.statuses,
                } );
                context.dispatch('initializeFilterStatusList');
            } catch ( error ) {
                const message = `Failed to get Invoice Status list - ${ getMessageFromError( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            }
        },

        /**
         * Search for Invoices by filters
         */
        async getByFilters( context ) {
            context.commit('SET_IN_FLIGHT', { boolean: true } );
            await context.dispatch( 'clearNotifications', { root: true } );
            try {
                const statusesToSend = [];
                Object.keys( context.state.filters.statusList ).forEach( ( status ) => {
                    if ( context.state.filters.statusList[ status ] ) {
                        statusesToSend.push( status );
                    }
                } );
                const { data } = await InvoiceApi.postFiltersToGetInvoiceList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    // Conditionally insert object attributes so we don't send blank information
                    payload: {
                        ...insertIf( context.state.filters.supplierDocumentNumber !== '', {
                            supplierDocumentNumber: context.state.filters.supplierDocumentNumber,
                        } ),
                        ...insertIf( context.state.filters.purchaseOrderNumber !== '', {
                            purchaseOrderNumber: context.state.filters.purchaseOrderNumber,
                        } ),
                        ...insertIf( context.state.filters.dateStart !== '', {
                            dateStart: context.state.filters.dateStart,
                        } ),
                        ...insertIf( context.state.filters.dateEnd !== '', {
                            dateEnd: context.state.filters.dateEnd,
                        } ),
                        ...insertIf( context.state.filters.isbn !== '', {
                            isbn: FormatUtil.sanitizeIsbn( context.state.filters.isbn ),
                        } ),
                        ...insertIf( context.state.filters.primarySupplier !== {}, {
                            supplierId: context.state.filters.primarySupplier.id,
                        } ),
                        ...insertIf( statusesToSend.length !== 0, {
                            statuses: statusesToSend,
                        } ),
                        ...insertIf( context.state.filters.location !== {} && context.state.filters.location.id !== 0, {
                            locationId: context.state.filters.location.id,
                        } ),
                        allLocations: context.state.filters.location === {} || context.state.filters.location.id === 0,
                    },
                } );
                context.commit('SET_LIST', {
                    list: data.invoiceSearchDTOs,
                } );
            } catch ( error ) {
                const message = `Failed to find any Invoices matching the filter criteria - ${ getMessageFromError( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                context.commit('SET_LIST', {
                    list: [],
                } );
            } finally {
                context.commit('SET_IN_FLIGHT', { boolean: false } );
            }
        },

        async create( context, { invoice } ) {
            context.commit('SET_IN_FLIGHT', { boolean: true } );
            try {
                const { data } = await InvoiceApi.createInvoice( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    payload: invoice,
                } );
                const invoiceId = data.id;
                const invoiceUrl = `#/invoice/${ invoiceId }`;
                const message = `Created Invoice <a href="${ invoiceUrl }" target="_blank">${ invoiceId }</a>`;
                context.dispatch( 'setSuccessNotification', message, { root: true } );
                return invoiceId;
            } catch ( error ) {
                const message = `Error occurred when creating this Invoice - ${ getMessageFromError( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( getMessageFromError( error ) );
            } finally {
                context.commit('SET_IN_FLIGHT', { boolean: false } );
            }
        },
    },
};
