import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * Validate a user's credentials
     * @param { string } authString
     * @returns { Promise }
     */
    validateUserCredentials({ authString }) {
        return axios({
            url: api.cmaUrl('qtp/test'),
            method: 'get',
            headers: api.authHeaders(authString),
        });
    },

    /**
     * Get a user's permissions
     * @param { string } authString
     * @returns { Promise }
     */
    getUserPermissions(authString) {
        return axios({
            url: api.cmaUrl('titleResearch/permissions/user'),
            method: 'get',
            headers: api.authHeaders(authString),
        });
    },
};
