import Vue from 'vue';

// Api
import PurchaseOrderApi from '@/api/PurchaseOrderApi';

// Utils
import ArrayUtil from '@/utils/ArrayUtil';
import FormatUtil from '@/utils/FormatUtil';
import ObjectUtil from '@/utils/ObjectUtil';
import FormUtil from '@/utils/FormUtil';
import StoreUtil from '@/utils/StoreUtil';

// Constants
import STATUS_LIST from '@/constants/purchaseOrders/statusList';

// Modules
import Detail from './Detail';
import MultiCreate from './MultiCreate';

const mutationTypes = {
    SET_LIST: 'SET_LIST',
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',
    UPDATE_PURCHASE_ORDER_IN_LIST: 'UPDATE_PURCHASE_ORDER_IN_LIST',
    UPDATE_PURCHASE_ORDER_LIST_PRINT_POST_HOOK: 'UPDATE_PURCHASE_ORDER_LIST_PRINT_POST_HOOK',
    SET_TABLE_FILTER: 'SET_TABLE_FILTER',

    SET_TABLE_SORT_BY: 'SET_TABLE_SORT_BY',
    SET_TABLE_SORT_DESC: 'SET_TABLE_SORT_DESC',

    SET_FILTER_PURCHASE_ORDER_NUMBER: 'PURCHASE_ORDER_NUMBER',
    SET_FILTER_EXPECTED_RECEIVE_START_DATE: 'EXPECTED_RECEIVE_START_DATE',
    SET_FILTER_EXPECTED_RECEIVE_END_DATE: 'EXPECTED_RECEIVE_END_DATE',
    SET_FILTER_CREATE_START_DATE: 'CREATE_START_DATE',
    SET_FILTER_CREATE_END_DATE: 'CREATE_END_DATE',
    SET_FILTER_TRANSMIT_START_DATE: 'TRANSMIT_START_DATE',
    SET_FILTER_TRANSMIT_END_DATE: 'TRANSMIT_END_DATE',
    SET_FILTER_LOCATION: 'LOCATION',
    SET_FILTER_PRODUCT_CODE: 'PRODUCT_CODE',
    SET_FILTER_SUPPLIER: 'SUPPLIER',
    SET_FILTER_STATUS_LIST: 'STATUS_LIST',
    SET_FILTER_NOTES_START_WITH: 'NOTES_START_WITH',
    SET_PURCHASE_ORDER_NUMBER: 'EMPTY_PURCHASE_ORDER_NUMBER',
};

const actionTypes = {
    getByFilters: 'getByFilters',
    autogenPreview: 'autogenPreview',
    autogenActual: 'autogenActual',
    create: 'create',
    modify: 'modify',
    moveLineItems: 'moveLineItems',
    print: 'print',
    reset: 'reset',
    clearFilters: 'clearFilters',
    setTableFilter: 'setTableFilter',
    initializeFilterStatusList: 'initializeFilterStatusList',
    postFiltersToGetPurchaseOrders: 'postFiltersToGetPurchaseOrders',
    postPurchaseOrderNumberToGetPurchaseOrders: 'postPurchaseOrderNumberToGetPurchaseOrders',
    setTableSortOptionsAndSortList: 'setTableSortOptionsAndSortList',
    sortAndSetList: 'sortAndSetList',
    getOpenToBuyInfo: 'getOpenToBuyInfo',

    setFilterPurchaseOrderNumber: 'setFilterPurchaseOrderNumber',
    setFilterExpectedReceiveStartDate: 'setFilterExpectedReceiveStartDate',
    setFilterExpectedReceiveEndDate: 'setFilterExpectedReceiveEndDate',
    setFilterCreateStartDate: 'setFilterCreateStartDate',
    setFilterCreateEndDate: 'setFilterCreateEndDate',
    setFilterTransmitStartDate: 'setFilterTransmitStartDate',
    setFilterTransmitEndDate: 'setFilterTransmitEndDate',
    setFilterLocation: 'setFilterLocation',
    setFilterProductCode: 'setFilterProductCode',
    setFilterSupplier: 'setFilterSupplier',
    setFilterStatusList: 'setFilterStatusList',
    setFilterNotesStartWith: 'setFilterNotesStartWith',
};

export default {
    namespaced: true,
    modules: {
        Detail,
        MultiCreate,
    },
    state: {
        /** @type { purchaseOrder[] } */
        list: [],
        /** @type { Boolean } - used to tell status of this.list */
        inFlight: false,
        /** @type { String[] } */
        purchaseOrderStatusList: Object.entries( STATUS_LIST ).map( ( status ) => ( {
            key: status[ 0 ],
            value: status[ 1 ],
        } ) ),
        /** @type { String } */
        createdPurchaseOrderNumber: '',
        /** @type { String } */
        tableFilter: '',
        table: {
            /** @type { String[] } */
            sortBy: [ 'expectedReceiveDate' ],
            /** @type { Boolean[] } */
            sortDesc: [ false ],
        },

        /** @type { Object} */
        filters: {
            /** @type { String } */
            purchaseOrderNumber: '',
            /** @type { String } */
            expectedReceiveStartDate: '',
            /** @type { String } */
            expectedReceiveEndDate: '',
            /** @type { String } */
            createStartDate: '',
            /** @type { String } */
            createEndDate: '',
            /** @type { String } */
            transmitStartDate: '',
            /** @type { String } */
            transmitEndDate: '',
            /** @type { location } */
            location: {},
            /** @type { String } */
            productCode: '',
            /** @type { primarySupplier } */
            supplier: {},
            /** @type { Object } */
            statusList: StoreUtil.status.initializeList( STATUS_LIST ),
            /** @type { String } */
            notesStartWith: '',
        },
    },
    getters: {
        /** @returns { Number } */
        purchaseOrderCount: ( state ) => state.list.length,
        /** @returns { string } */
        purchaseOrderNumber: (state) => state.createdPurchaseOrderNumber,
        /** @returns { Boolean } */
        hasPurchaseOrders: ( state, getters ) => (
            getters.purchaseOrderCount > 0
        ),

        /** @returns { Function } */
        hasItems: () => ( purchaseOrder ) => (
            !!purchaseOrder.purchaseOrderItemDTOList
            && !!purchaseOrder.purchaseOrderItemDTOList.length
            && ( purchaseOrder.purchaseOrderItemDTOList.length > 0 )
        ),
        /** @returns { Function } */
        hasNoItems: () => ( purchaseOrder ) => (
            !!purchaseOrder.purchaseOrderItemDTOList
            && ( purchaseOrder.purchaseOrderItemDTOList.length === 0 )
        ),

        sanitizePurchaseOrder: ( state, getters ) => ( purchaseOrder ) => ( {
            confirmationId: purchaseOrder.confirmationId || null,
            createDate: purchaseOrder.createDate || '',
            description: purchaseOrder.description || '',
            notesFriendly: purchaseOrder.description || '',
            expectedReceiveDate: purchaseOrder.expectedReceiveDate || null,
            fees: purchaseOrder.fees || 0,
            invoices: purchaseOrder.invoices || [],
            id: purchaseOrder.id || purchaseOrder.number || '',
            location: purchaseOrder.location || {},
            locationFriendly: purchaseOrder.location && purchaseOrder.location.name
                ? FormatUtil.locationNameToInitials( purchaseOrder.location.name )
                : '',
            number: purchaseOrder.number || '',
            prefixOverride: purchaseOrder.prefixOverride || null,
            purchaseOrderCreditCardDTO: {
                fees: purchaseOrder.purchaseOrderCreditCardDTO.fees || 0,
                lastFour: purchaseOrder.purchaseOrderCreditCardDTO.lastFour || '',
                note: purchaseOrder.purchaseOrderCreditCardDTO.note || '',
                originalSubtotal: purchaseOrder.purchaseOrderCreditCardDTO.originalSubtotal || 0,
                shipping: purchaseOrder.purchaseOrderCreditCardDTO.shipping || 0,
            },
            purchaseOrderItemDTOList: purchaseOrder.purchaseOrderItemDTOList.length > 0
                ? purchaseOrder.purchaseOrderItemDTOList.map( ( item ) => ( {
                    ...item,
                    backordered: item?.backordered ?? false,
                } ) )
                : [],
            shippingCost: purchaseOrder.shippingCost || 0,
            status: purchaseOrder.status || '',
            supplier: purchaseOrder.supplier || {},
            supplierFriendly: purchaseOrder.supplier && purchaseOrder.supplier.name
                ? purchaseOrder.supplier.name
                : 'Not Set',
            supplierNote: purchaseOrder.supplierNote || '',
            trackingNumbers: purchaseOrder.trackingNumbers || '',
            transmitDate: purchaseOrder.transmitDate || null,
            useCreditCard: purchaseOrder.useCreditCard || false,
            unitsOrdered: getters.hasItems( purchaseOrder )
                ? purchaseOrder.purchaseOrderItemDTOList
                    .map( ( item ) => item.requestedQuantity )
                    .reduce( ( accumulator, currentValue ) => accumulator + currentValue )
                : 0,
            isbnsOrdered: getters.hasItems( purchaseOrder )
                ? new Set( purchaseOrder.purchaseOrderItemDTOList.map( ( item ) => item.productCode ) ).size
                : 0,
        } ),

        /** @returns { Function } */
        hasConflictingItems: ( state, getters ) => ( purchaseOrder ) => {
            const mapList = purchaseOrder.purchaseOrderItemDTOList
                .map( ( item ) => `${ item.condition }${ item.productCode }${ item.toBeDeleted }`.toLowerCase() );

            // Idea from : https://hackernoon.com/finding-non-unique-elements-in-javascript-d934e6fd6260
            return getters.hasItems( purchaseOrder )
                    && mapList.filter( ( item ) => mapList.indexOf( item ) !== mapList.lastIndexOf( item ) ).length > 0;
        },
    },
    actions: {
        [ actionTypes.postFiltersToGetPurchaseOrders ]( context ) {
            const statusesToSend = StoreUtil.status.getListFromStatusObject( context.state.filters.statusList );
            const payload = {
                ...FormUtil.insertIf( context.state.filters.purchaseOrderNumber !== '', {
                    purchaseOrderNumber: context.state.filters.purchaseOrderNumber,
                } ),
                ...FormUtil.insertIf( context.state.filters.expectedReceiveStartDate !== '', {
                    expectedReceiveStartDate: context.state.filters.expectedReceiveStartDate,
                } ),
                ...FormUtil.insertIf( context.state.filters.expectedReceiveEndDate !== '', {
                    expectedReceiveEndDate: context.state.filters.expectedReceiveEndDate,
                } ),
                ...FormUtil.insertIf( context.state.filters.createStartDate !== '', {
                    createStartDate: context.state.filters.createStartDate,
                } ),
                ...FormUtil.insertIf( context.state.filters.createEndDate !== '', {
                    createEndDate: context.state.filters.createEndDate,
                } ),
                ...FormUtil.insertIf( context.state.filters.transmitStartDate !== '', {
                    transmitStartDate: context.state.filters.transmitStartDate,
                } ),
                ...FormUtil.insertIf( context.state.filters.transmitEndDate !== '', {
                    transmitEndDate: context.state.filters.transmitEndDate,
                } ),
                ...FormUtil.insertIf( ObjectUtil.isNotEmpty( context.state.filters.location ), {
                    locationId: context.state.filters.location.id,
                } ),
                ...FormUtil.insertIf( context.state.filters.productCode !== '', {
                    productCode: FormatUtil.sanitizeIsbn( context.state.filters.productCode ),
                } ),
                ...FormUtil.insertIf( context.state.filters.supplier?.id, {
                    supplierId: context.state.filters.supplier.id,
                } ),
                ...FormUtil.insertIf( !!statusesToSend && statusesToSend.length !== 0, {
                    sourcingDocStatusList: statusesToSend,
                } ),
                ...FormUtil.insertIf( context.state.filters.notesStartWith.length !== 0, {
                    description: context.state.filters.notesStartWith,
                } ),
            };
            // Conditionally insert object attributes so we don't send blank information
            context.dispatch( actionTypes.getByFilters, payload );
        },
        [ actionTypes.postPurchaseOrderNumberToGetPurchaseOrders ]( context ) {
            if ( !context.state.filters.purchaseOrderNumber ) {
                context.dispatch( 'setErrorNotification', 'A purchase order number must be set to search by it.', { root: true } );
                return;
            }
            context.dispatch( actionTypes.getByFilters, {
                purchaseOrderNumber: context.state.filters.purchaseOrderNumber,
            } );
        },
        [ actionTypes.reset ]( { commit, dispatch } ) {
            commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            commit( mutationTypes.SET_LIST, { list: [] } );
            commit( mutationTypes.SET_TABLE_SORT_BY, { sortBy: [ 'expectedReceiveDate' ] } );
            commit( mutationTypes.SET_TABLE_SORT_DESC, { sortDesc: [ false ] } );
            dispatch( actionTypes.clearFilters );
        },

        [ actionTypes.clearFilters ]( { dispatch } ) {
            dispatch( actionTypes.setFilterPurchaseOrderNumber, { purchaseOrderNumber: '' } );
            dispatch( actionTypes.setFilterExpectedReceiveStartDate, { expectedReceiveStartDate: '' } );
            dispatch( actionTypes.setFilterExpectedReceiveEndDate, { expectedReceiveEndDate: '' } );
            dispatch( actionTypes.setFilterCreateStartDate, { createStartDate: '' } );
            dispatch( actionTypes.setFilterCreateEndDate, { createEndDate: '' } );
            dispatch( actionTypes.setFilterTransmitStartDate, { transmitStartDate: '' } );
            dispatch( actionTypes.setFilterTransmitEndDate, { transmitEndDate: '' } );
            dispatch( actionTypes.setFilterProductCode, { productCode: '' } );
            dispatch( actionTypes.setFilterLocation, { location: {} } );
            dispatch( actionTypes.setFilterSupplier, { supplier: {} } );
            dispatch( actionTypes.setFilterNotesStartWith, { notesStartWith: '' } );
            dispatch( actionTypes.setTableFilter, { tableFilter: '' } );
            dispatch( actionTypes.initializeFilterStatusList );
        },

        [ actionTypes.setTableFilter ]( { commit }, { tableFilter } ) {
            commit( mutationTypes.SET_TABLE_FILTER, { tableFilter } );
        },

        async [ actionTypes.setTableSortOptionsAndSortList ]( context, { sortBy, sortDesc } ) {
            context.commit( mutationTypes.SET_TABLE_SORT_BY, { sortBy } );
            context.commit( mutationTypes.SET_TABLE_SORT_DESC, { sortDesc } );
            if ( context.state.list.length > 0 ) {
                await context.dispatch( actionTypes.sortAndSetList, { list: context.state.list } );
            }
        },
        /**
         * Sort and set the list of sanitized purchase orders
         * @param context
         * @param list - a list of purchase orders that have been run through context.getters.sanitizePurchaseOrder
         */
        [ actionTypes.sortAndSetList ]( context, { list } ) {
            context.commit( mutationTypes.SET_LIST, {
                list: ArrayUtil.sort( list, context.state.table.sortBy, context.state.table.sortDesc ),
            } );
        },

        async getOpenToBuyInfo( context, { productCode, allowSubscription } ) {
            try {
                const { data } = await PurchaseOrderApi.retrieveOpenToBuyList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    productCode,
                    allowSubscription,
                } );
                return data;
            } catch ( error ) {
                throw new Error( StoreUtil.error.getMessage( error ) );
            }
        },

        /**
         * Search for Purchase Orders by filters
         */
        async [ actionTypes.getByFilters ]( context, payload ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await PurchaseOrderApi.postFiltersToGetPurchaseOrderList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    ...payload,
                } );
                if ( data && data.purchaseOrderDetailDTOList.length && data.purchaseOrderDetailDTOList.length > 0 ) {
                    await context.dispatch( actionTypes.sortAndSetList, {
                        list: data.purchaseOrderDetailDTOList.map( context.getters.sanitizePurchaseOrder ),
                    } );
                } else {
                    await context.dispatch( 'setSuccessNotification', 'There are no Purchase Orders matching the filter criteria', { root: true } );
                }
            } catch ( error ) {
                const message = `Failed to find any Purchase Orders matching the filter criteria - ${ error?.response?.data?.message ?? error?.message }`;
                await context.dispatch( 'setErrorNotification', message, { root: true } );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        /**
         * Preview AutoGen Orders
         */
        async [ actionTypes.autogenPreview ]( context, payload ) {
            try {
                await PurchaseOrderApi.autoGenPurchaseOrders( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    ...payload,
                    previewOnly: true,
                } );
                context.dispatch( 'setSuccessNotification', 'Generating AutoGen preview. Check your email!', { root: true } );
            } catch ( error ) {
                context.dispatch( 'setErrorNotification', `${ error?.response?.data?.message ?? error?.message }`, { root: true } );
            }
        },

        /**
         * Begin the AutoGen process
         */
        async [ actionTypes.autogenActual ]( context, payload ) {
            try {
                await PurchaseOrderApi.autoGenPurchaseOrders( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    ...payload,
                    previewOnly: false,
                } );
                context.dispatch( 'setSuccessNotification', 'Began the AutoGen process.', { root: true } );
            } catch ( error ) {
                context.dispatch( 'setErrorNotification', `${ error?.response?.data?.message ?? error?.message }`, { root: true } );
            }
        },

        /**
         * Create a Purchase Order
         */
        async [ actionTypes.create ]( context, payload ) {
            try {
                const { data } = await PurchaseOrderApi.createPurchaseOrder( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    ...payload,
                } );

                if ( data && data.purchaseOrderDTO && data.purchaseOrderDTO.number ) {
                    const purchaseOrderNumber = data.purchaseOrderDTO.number;
                    const purchaseOrderUrl = `#/search/${ purchaseOrderNumber }`;
                    const message = `Created <a href="${ purchaseOrderUrl }" target="_blank">${ purchaseOrderNumber }</a>`;
                    context.commit( mutationTypes.SET_PURCHASE_ORDER_NUMBER, { purchaseOrderNumber } );
                    context.dispatch( 'setSuccessNotification', message, { root: true } );
                } else {
                    throw new Error( 'PO was probably created successfully, but could not extract a success message' );
                }
            } catch ( error ) {
                const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error;
                context.dispatch( 'setErrorNotification', `Failed to create Purchase Order - ${ message }`, { root: true } );
            }
        },

        /**
         * Modify a given Purchase Order
         */
        async [ actionTypes.modify ]( context, { purchaseOrder } ) {
            let formattedPurchaseOrder = {};
            try {
                const mutablePurchaseOrder = JSON.parse( JSON.stringify( purchaseOrder ) );
                // Rename 'supplier' to 'supplierDto'
                if ( 'supplier' in mutablePurchaseOrder ) {
                    formattedPurchaseOrder.supplierDto = mutablePurchaseOrder.supplier;
                    delete mutablePurchaseOrder.supplier;
                }
                // Only include 'purchaseOrderCreditCardDTO' if 'useCreditCard' is true
                if ( 'purchaseOrderCreditCardDTO' in mutablePurchaseOrder ) {
                    if ( 'useCreditCard' in mutablePurchaseOrder ) {
                        formattedPurchaseOrder.purchaseOrderCreditCardDTO = {
                            ...mutablePurchaseOrder.purchaseOrderCreditCardDTO,
                            originalSubtotal: parseFloat( context.rootGetters[ 'PurchaseOrder/Detail/currentPurchaseOrderSubtotal' ] ),
                        };
                    }
                    delete mutablePurchaseOrder.purchaseOrderCreditCardDTO;
                }
                // Rename 'purchaseOrderItemDTOList' to 'modifyPurchaseOrderItemDTOList', and format the list for the backend
                if ( 'purchaseOrderItemDTOList' in mutablePurchaseOrder ) {
                    if ( mutablePurchaseOrder.purchaseOrderItemDTOList.length > 0 ) {
                        formattedPurchaseOrder.modifyPurchaseOrderItemDTOList = mutablePurchaseOrder.purchaseOrderItemDTOList
                            .map( ( purchaseOrderItem ) => ( {
                                toBeDeleted: purchaseOrderItem.toBeDeleted,
                                id: purchaseOrderItem.id || null,
                                backordered: purchaseOrderItem?.backordered ?? false,
                                condition: purchaseOrderItem.condition,
                                productCode: purchaseOrderItem.productCode,
                                quantity: purchaseOrderItem.requestedQuantity,
                                price: purchaseOrderItem.requestedPrice,
                                expectedReceiveDate: purchaseOrderItem.expectedReceiveDate || '',
                                note: purchaseOrderItem.note || null,
                            } ) );
                    }
                    delete mutablePurchaseOrder.purchaseOrderItemDTOList;
                }
                // Remove extraneous attributes added for display purposes
                if ( 'isbnsOrdered' in mutablePurchaseOrder ) {
                    delete mutablePurchaseOrder.isbnsOrdered;
                }
                if ( 'unitsOrdered' in mutablePurchaseOrder ) {
                    delete mutablePurchaseOrder.unitsOrdered;
                }
                if ( 'locationFriendly' in mutablePurchaseOrder ) {
                    delete mutablePurchaseOrder.locationFriendly;
                }
                if ( 'notesFriendly' in mutablePurchaseOrder ) {
                    delete mutablePurchaseOrder.notesFriendly;
                }
                if ( 'supplierFriendly' in mutablePurchaseOrder ) {
                    delete mutablePurchaseOrder.supplierFriendly;
                }
                // Smash all of our changes together
                formattedPurchaseOrder = {
                    ...formattedPurchaseOrder,
                    ...mutablePurchaseOrder,
                };
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            } catch ( error ) {
                console.log( error );
                const message = `
                    System error while saving Purchase order - contact support and keep this page open.
                    SOS Ticket: https://treeoflifebooks.atlassian.net/browse/SOS-3771
                `;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            }

            try {
                const { data } = await PurchaseOrderApi.modifyPurchaseOrder( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    purchaseOrderList: [ formattedPurchaseOrder ],
                } );

                // Update the purchase order in our list
                context.commit( mutationTypes.UPDATE_PURCHASE_ORDER_IN_LIST, {
                    purchaseOrder: context.getters.sanitizePurchaseOrder( data.purchaseOrderDetailDTOList[ 0 ] ),
                } );

                // Update Purchase Order Detail's currentPurchaseOrder
                await context.dispatch( 'PurchaseOrder/Detail/purchaseOrderModifyPostHook', {
                    purchaseOrder: context.getters.sanitizePurchaseOrder( data.purchaseOrderDetailDTOList[ 0 ] ),
                }, { root: true } );

                // Prepare line items
                await context.dispatch( 'PurchaseOrder/Detail/prepareCurrentPurchaseOrderLineItems', {}, { root: true } );

                // TODO Remove log and detailed portion of success toast after save bug is found and fixed
                let message = `Successfully modified ${ purchaseOrder.number }.\nModified Fields: `;
                const modifiedFields = Object.keys( formattedPurchaseOrder ).filter( ( key ) => key !== 'number' ).join( ', ' );
                message += modifiedFields;
                console.log( JSON.stringify( formattedPurchaseOrder ) );

                // Notify of success
                context.dispatch( 'setSuccessNotification', message, { root: true } );
            } catch ( error ) {
                const message = `Failed to modify Purchase Order - ${ error.response.data.message || error.message }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( error.message );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        /**
         * Move line items from one purchase order to another
         */
        async [ actionTypes.moveLineItems ]( context, payload ) {
            try {
                const { data } = await PurchaseOrderApi.movePurchaseOrderItems( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    ...payload,
                } );

                await context.dispatch( 'PurchaseOrder/Detail/removeMovedLineItemsFromCurrentPurchaseOrder', {
                    itemIDList: payload.itemIDList,
                }, { root: true } );

                // Update the purchase order we just moved items FROM in our list
                context.commit( mutationTypes.UPDATE_PURCHASE_ORDER_IN_LIST, {
                    purchaseOrder: context.getters.sanitizePurchaseOrder( context.rootState.PurchaseOrder.Detail.currentPurchaseOrder ),
                } );

                // Update target Purchase Order if it exists in our list
                context.commit( mutationTypes.UPDATE_PURCHASE_ORDER_IN_LIST, {
                    purchaseOrder: context.getters.sanitizePurchaseOrder( data.purchaseOrderDetailDTOList[ 1 ] ),
                } );

                // Notify of success
                context.dispatch( 'setSuccessNotification', `Moved items to ${ data.purchaseOrderDetailDTOList[ 1 ].number }`, { root: true } );
            } catch ( error ) {
                context.dispatch( 'setErrorNotification', `Failed to move Purchase Order items - ${ error.response.data.message }`, { root: true } );
            }
        },

        /**
         * Print purchase orders
         * Open Jasper reports for each 'printed' Purchase Order
         * Change the status of selected Purchase Orders in the 'DRAFT' status to the 'PRINTED' status
         */
        async [ actionTypes.print ]( context, { purchaseOrderList } ) {
            try {
                // Get the URL to see the 'printed' Purchase Orders
                const response = await PurchaseOrderApi.printPurchaseOrders( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    purchaseOrderList: purchaseOrderList.map( ( purchaseOrder ) => ( { number: purchaseOrder.number } ) ),
                } );

                // Create a list of Purchase Orders in the 'DRAFT' status, and change their status to 'PRINTED'
                const filteredPurchaseOrderList = purchaseOrderList
                    .filter( ( purchaseOrder ) => purchaseOrder.status === 'DRAFT' )
                    .map( ( purchaseOrder ) => ( {
                        number: purchaseOrder.number,
                        status: 'PRINTED',
                    } ) );

                // If we have items in filteredPurchaseOrderList
                if ( filteredPurchaseOrderList && filteredPurchaseOrderList.length && filteredPurchaseOrderList.length > 0 ) {
                    try {
                        // Modify the purchaseOrderList to set their status to 'PRINTED'
                        const { data } = await PurchaseOrderApi.modifyPurchaseOrder( {
                            authToken: context.rootGetters[ 'User/authString' ],
                            purchaseOrderList: filteredPurchaseOrderList,
                        } );

                        // Set the status on each printed Purchase Order to 'PRINTED' in our list
                        context.commit( mutationTypes.UPDATE_PURCHASE_ORDER_LIST_PRINT_POST_HOOK, {
                            purchaseOrderList: data.purchaseOrderDetailDTOList.map( context.getters.sanitizePurchaseOrder ),
                        } );

                        // Notify of success
                        context.dispatch( 'setSuccessNotification', 'Successfully printed Purchase Orders', { root: true } );
                    } catch ( error ) {
                        const message = `Failed to set a Purchase Order's status - ${ error.response.data.message }`;
                        context.dispatch( 'setErrorNotification', message, { root: true } );
                    }
                }
                // Open the url
                setTimeout( () => window.open( response.data.url, '_blank' ), 1000 );
            } catch ( error ) {
                const message = `Failed to Print Purchase Orders - ${ error.response.data.message }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            }
        },

        async [ actionTypes.initializeFilterStatusList ]( context ) {
            const statusList = {};

            context.state.purchaseOrderStatusList.forEach( ( status ) => {
                statusList[ status.key ] = false;
            } );
            await context.dispatch( actionTypes.setFilterStatusList, { statusList } );
        },

        [ actionTypes.setFilterPurchaseOrderNumber ]( context, { purchaseOrderNumber } ) {
            context.commit( mutationTypes.SET_FILTER_PURCHASE_ORDER_NUMBER, { purchaseOrderNumber } );
        },
        [ actionTypes.setFilterExpectedReceiveStartDate ]( context, { expectedReceiveStartDate } ) {
            context.commit( mutationTypes.SET_FILTER_EXPECTED_RECEIVE_START_DATE, { expectedReceiveStartDate } );
        },
        [ actionTypes.setFilterExpectedReceiveEndDate ]( context, { expectedReceiveEndDate } ) {
            context.commit( mutationTypes.SET_FILTER_EXPECTED_RECEIVE_END_DATE, { expectedReceiveEndDate } );
        },
        [ actionTypes.setFilterCreateStartDate ]( context, { createStartDate } ) {
            context.commit( mutationTypes.SET_FILTER_CREATE_START_DATE, { createStartDate } );
        },
        [ actionTypes.setFilterCreateEndDate ]( context, { createEndDate } ) {
            context.commit( mutationTypes.SET_FILTER_CREATE_END_DATE, { createEndDate } );
        },
        [ actionTypes.setFilterTransmitStartDate ]( context, { transmitStartDate } ) {
            context.commit( mutationTypes.SET_FILTER_TRANSMIT_START_DATE, { transmitStartDate } );
        },
        [ actionTypes.setFilterTransmitEndDate ]( context, { transmitEndDate } ) {
            context.commit( mutationTypes.SET_FILTER_TRANSMIT_END_DATE, { transmitEndDate } );
        },
        [ actionTypes.setFilterLocation ]( context, { location } ) {
            context.commit( mutationTypes.SET_FILTER_LOCATION, { location } );
        },
        [ actionTypes.setFilterProductCode ]( context, { productCode } ) {
            context.commit( mutationTypes.SET_FILTER_PRODUCT_CODE, { productCode } );
        },
        [ actionTypes.setFilterSupplier ]( context, { supplier } ) {
            context.commit( mutationTypes.SET_FILTER_SUPPLIER, { supplier } );
        },
        [ actionTypes.setFilterStatusList ]( context, { statusList } ) {
            context.commit( mutationTypes.SET_FILTER_STATUS_LIST, { statusList } );
        },
        [ actionTypes.setFilterNotesStartWith ]( context, { notesStartWith } ) {
            context.commit( mutationTypes.SET_FILTER_NOTES_START_WITH, { notesStartWith } );
        },
        async sendToPubNet({ dispatch, rootGetters }, purchaseOrders) {
            const purchaseOrderNumbers = purchaseOrders.map(({ number }) => number);

            try {
                await PurchaseOrderApi.sendToPubNet(
                    rootGetters['User/authString'],
                    purchaseOrderNumbers,
                );
            } catch (error) {
                dispatch('setErrorNotification', error.message, { root: true });
            }
        },
    },
    mutations: {
        [ mutationTypes.SET_LIST ]( state, { list } ) {
            state.list = list;
        },
        [ mutationTypes.SET_IN_FLIGHT ]( state, { boolean } ) {
            state.inFlight = boolean;
        },
        [ mutationTypes.UPDATE_PURCHASE_ORDER_IN_LIST ]( state, { purchaseOrder } ) {
            // Use Vue to update the correct purchase order in the state.list
            // If you don't use Vue to set this, and do something like this: state.list[ index ] = item
            // Vue won't be able to track the change.
            const index = state.list.findIndex( ( item ) => item.number === purchaseOrder.number );
            if ( index !== -1 ) {
                Vue.set(
                    state.list,
                    index,
                    purchaseOrder,
                );
            }
        },
        [ mutationTypes.UPDATE_PURCHASE_ORDER_LIST_PRINT_POST_HOOK ]( state, { purchaseOrderList } ) {
            // For each purchase order that was printed, set it's status to 'PRINTED'
            purchaseOrderList.forEach( ( printedPurchaseOrder ) => {
                Vue.set(
                    state.list[
                        state.list.findIndex( ( purchaseOrder ) => purchaseOrder.number === printedPurchaseOrder.number )
                    ],
                    'status',
                    'PRINTED',
                );
            } );
        },
        [ mutationTypes.SET_TABLE_FILTER ]( state, { tableFilter } ) {
            state.tableFilter = tableFilter;
        },

        [ mutationTypes.SET_FILTER_PURCHASE_ORDER_NUMBER ]( state, { purchaseOrderNumber } ) {
            state.filters.purchaseOrderNumber = purchaseOrderNumber;
        },
        [ mutationTypes.SET_FILTER_EXPECTED_RECEIVE_START_DATE ]( state, { expectedReceiveStartDate } ) {
            state.filters.expectedReceiveStartDate = expectedReceiveStartDate;
        },
        [ mutationTypes.SET_FILTER_EXPECTED_RECEIVE_END_DATE ]( state, { expectedReceiveEndDate } ) {
            state.filters.expectedReceiveEndDate = expectedReceiveEndDate;
        },
        [ mutationTypes.SET_FILTER_CREATE_START_DATE ]( state, { createStartDate } ) {
            state.filters.createStartDate = createStartDate;
        },
        [ mutationTypes.SET_FILTER_CREATE_END_DATE ]( state, { createEndDate } ) {
            state.filters.createEndDate = createEndDate;
        },
        [ mutationTypes.SET_FILTER_TRANSMIT_START_DATE ]( state, { transmitStartDate } ) {
            state.filters.transmitStartDate = transmitStartDate;
        },
        [ mutationTypes.SET_FILTER_TRANSMIT_END_DATE ]( state, { transmitEndDate } ) {
            state.filters.transmitEndDate = transmitEndDate;
        },
        [ mutationTypes.SET_FILTER_LOCATION ]( state, { location } ) {
            state.filters.location = location;
        },
        [ mutationTypes.SET_FILTER_PRODUCT_CODE ]( state, { productCode } ) {
            state.filters.productCode = productCode;
        },
        [ mutationTypes.SET_FILTER_SUPPLIER ]( state, { supplier } ) {
            state.filters.supplier = supplier;
        },
        [ mutationTypes.SET_FILTER_STATUS_LIST ]( state, { statusList } ) {
            state.filters.statusList = statusList;
        },
        [ mutationTypes.SET_FILTER_NOTES_START_WITH ]( state, { notesStartWith } ) {
            state.filters.notesStartWith = notesStartWith;
        },
        [ mutationTypes.SET_TABLE_SORT_BY ]( state, { sortBy } ) {
            state.table.sortBy = sortBy;
        },
        [ mutationTypes.SET_TABLE_SORT_DESC ]( state, { sortDesc } ) {
            state.table.sortDesc = sortDesc;
        },
        [ mutationTypes.SET_PURCHASE_ORDER_NUMBER ]( state, { purchaseOrderNumber } ) {
            state.createdPurchaseOrderNumber = purchaseOrderNumber;
        },

    },
};
