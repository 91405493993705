import AuthApi from '@/api/AuthApi';
import LocalAuthApi from '@/api/LocalAuthApi';

function getAuthString(username, password) {
    return `Basic ${ btoa(`${ username }:${ password }`) }`;
}

export default {
    namespaced: true,
    state: {
        /** @type { String } */
        username: '',
        /** @type { String } */
        password: '',
        /** @type { Array<string> } */
        permissions: [],
    },
    getters: {
        /** @returns { Boolean } */
        isLoggedIn: (state) => (state.username.length > 0) && (state.password.length > 0),

        /** @returns { Boolean } */
        isLoggedOut: (state, getters) => !getters.isLoggedIn,

        /** @returns { String } */
        authString: (state) => getAuthString(state.username, state.password),

        /** @returns { boolean } */
        canSendToPubNet: (state) => state.permissions.includes('ALLOW_SEND_TO_PUBNET'),
    },
    actions: {
        clearSessionData( { dispatch } ) {
            dispatch('reset', {}, { root: true } );
            dispatch('Adoption/reset', {}, { root: true } );
            dispatch('CreditMemo/reset', {}, { root: true } );
            dispatch('CreditMemo/Detail/reset', {}, { root: true } );
            dispatch('CatalogListing/reset', {}, { root: true } );
            dispatch('GlobalResources/reset', {}, { root: true } );
            dispatch('Invoice/reset', {}, { root: true } );
            dispatch('Invoice/Detail/reset', {}, { root: true } );
            dispatch('PurchaseOrder/reset', {}, { root: true } );
            dispatch('RRA/AutoGen/reset', {}, { root: true } );
            dispatch('RRA/Detail/reset', {}, { root: true } );
            dispatch('RRA/Detail/EANDetail/reset', {}, { root: true } );
            dispatch('Title/Assignment/reset', {}, { root: true } );
            dispatch('Title/Queue/reset', {}, { root: true } );
            dispatch('TitleGroup/reset', {}, { root: true } );
        },
        async logout({ commit, dispatch }) {
            await dispatch('clearSessionData');
            commit('LOGOUT');
        },
        async login({ commit, dispatch }, { username, password } ) {
            const authString = getAuthString(username, password);
            try {
                await AuthApi.validateUserCredentials({ authString });
                commit('SET_USER_CREDENTIALS', { username, password });
                dispatch('fetchUserPermissions', authString);
            } catch ( error ) {
                throw Error('Invalid credentials. Try again.');
            }
        },
        // NOTE: I don't think this is being used
        setUserCredentialsFromLocalStorage({ commit }) {
            commit('SET_USER_CREDENTIALS_FROM_LOCAL_STORAGE');
        },
        async fetchUserPermissions({ commit }, authString) {
            try {
                const { data } = await AuthApi.getUserPermissions(authString);
                commit('SET_PERMISSIONS', data);
            } catch (error) {
                console.error(error);
            }
        },
    },
    mutations: {
        SET_USER_CREDENTIALS(state, { username, password }) {
            state.username = username;
            state.password = password;
            LocalAuthApi.username.set(username);
            LocalAuthApi.password.set(password);
        },
        SET_USER_CREDENTIALS_FROM_LOCAL_STORAGE(state) {
            state.username = LocalAuthApi.username.get();
            state.password = LocalAuthApi.password.get();
        },
        LOGOUT(state) {
            state.username = '';
            state.password = '';
            LocalAuthApi.removeLocalCredentials();
        },
        SET_PERMISSIONS(state, permissions) {
            state.permissions = permissions.actions;
        },
    },
};
